<script setup lang="ts">
</script>

<template>
  <v-container class="text-left pa-10">
    <v-sheet
      class="mx-auto mt-4"
      color="transparent"
      elevation="0"
      max-width="1600"
    >
      <v-row>
        <v-col cols="12" md="3">
          <h3>AIGCoder AI极客</h3>
          <p class="my-4">AIGCoder.com 2024 © All Rights Reserved</p>
          <p class="text-body-2 text-primary mt-2">
            <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024296601号-1</a>
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="auto" class="text-right">
              <v-btn size="120" color="white" class="px-0 mr-4">
                <img src="@/assets/logo.png" width="100" alt="AI极客"/>
              </v-btn>
            </v-col>
            <v-col cols="auto" class="text-left pl-2">
              <h1 class="font-weight-black text-h5">AIGC优质站点导航</h1>
              <h1 class="font-weight-black text-h4 mt-4">AIGC低代码平台</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-img src="/sites/aigcoder_dy.png" style="width: 350px;height:120px;" />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<style scoped lang="scss"></style>
