<script setup lang="ts">
import { useDisplay } from "vuetify"
// import ToolbarPromptsMenu from "./ToolbarPromptsMenu.vue"
// import ToolbarModelsMenu from "./ToolbarModelsMenu.vue"
import PrimaryColors from "@/components/PrimaryColors.vue"
// import ToolbarNotifications from "./ToolbarNotifications.vue"
import ToolbarUser from "./ToolbarUser.vue"
// import { useTodoStore } from "@/stores/todoStore"
import ThemeToggle from "./ThemeToggle.vue"
const { mdAndUp } = useDisplay()
// const todoStore = useTodoStore()
const showMobileSearch = ref(false)
</script>

<template>
  <v-app-bar :density="mdAndUp ? 'default' : 'compact'">
    <div class="d-flex flex-fill align-center" v-if="showMobileSearch">
      <v-text-field
        color="primary"
        variant="solo"
        prepend-inner-icon="mdi-magnify"
        append-inner-icon="mdi-close"
        @click:append-inner="showMobileSearch = false"
        hide-details
        placeholder="Search"
      ></v-text-field>
    </div>
    <div v-else class="px-2 d-flex align-center justify-space-between w-100">
      <v-card-title>
        <v-btn color="white" icon>
          <img src="@/assets/logo2.png" :width="40" alt="AIGCoder"/>
        </v-btn>
        AI极客
      </v-card-title>

      <v-btn class="text-none" to="/home" stacked> AIGC导航 </v-btn>
      <v-btn class="text-none position-relative" to="/code-magic" stacked>
        <span class="pa-2">
          代码魔法
        </span>
        <v-icon
          size="18"
          color="green"
          class="position-absolute"
          style="top: 12px; right: 2px;"
        >
          mdi-leaf
        </v-icon>
      </v-btn>
      <v-btn class="text-none" to="/guide/quick-start" stacked> 快速上手 </v-btn>
      <v-btn class="text-none" to="/guide/docs" stacked> 进阶技巧 </v-btn>
      <v-btn class="text-none position-relative" to="/aui" stacked>
        <span class="pa-2">
          纯血鸿蒙组件库
        </span>
        <v-icon
          size="18"
          color="red"
          class="position-absolute"
          style="top: 12px; right: 2px;"
        >
          mdi-fire
        </v-icon>
      </v-btn>
      <v-btn class="text-none" to="/update-logs" stacked> 升级日志 </v-btn>
      <v-btn class="text-none" to="/help" stacked> 帮助与反馈 </v-btn>

      <!--      <ToolbarPromptsMenu/>-->
      <!--      <ToolbarModelsMenu/>-->
      <!--      <v-btn class="text-none" to="/chatbot" stacked> 聊天机器人 </v-btn>-->
      <!--      <v-btn class="text-none" to="/imagebot" stacked> 图片机器人 </v-btn>-->
      <!--      <v-btn class="text-none" to="/news" stacked> AI资讯 </v-btn>-->
      <!--      <v-btn class="text-none" to="/compare" stacked> AI评测 </v-btn>-->
      <!--      <v-btn class="text-none" to="/comfyui" stacked> ComfyUI工作流 </v-btn>-->
      <!--      <v-btn class="text-none" to="/market" stacked> AI超市 </v-btn>-->


      <!--      <v-btn class="text-none" to="/test" stacked> 灵力测试 </v-btn>-->
      <!--      <v-btn class="text-none" to="/qinglong" stacked> 青龙圣域 </v-btn>-->
      <!--      <v-btn class="text-none" to="/baihu" stacked> 白虎天阙 </v-btn>-->
      <!--      <v-btn class="text-none" to="/zhuque" stacked> 朱雀神殿 </v-btn>-->
      <!--      <v-btn class="text-none" to="/xuanwu" stacked> 玄武灵墟 </v-btn>-->
      <!--      <v-btn class="text-none" to="/secret" stacked> AI秘境 </v-btn>-->
      <!--      <v-btn class="text-none" to="/task" stacked> 任务堂 </v-btn>-->
      <!--      <v-btn class="text-none" to="/top" stacked> 极客榜 </v-btn>-->
      <!--      <v-btn class="text-none" to="/study" stacked> 藏经阁 </v-btn>-->
      <!--      <v-btn class="text-none" to="/story" stacked> 代码元宇宙 </v-btn>-->


      <v-spacer></v-spacer>

      <div class="d-flex">
<!--        <ToolbarNotifications />-->
<!--        <v-btn v-if="mdAndUp" icon to="/apps/todo">-->
<!--          <v-badge :content="`${todoStore.getTodoList.length} +`" color="error">-->
<!--            <v-icon>mdi-calendar-check</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->
<!--        <v-divider vertical thickness="2" inset class="ml-5 mr-1"></v-divider>-->
        <!--切换深色模式/浅色模式-->
        <ThemeToggle />
        <!--选择主题色-->
        <PrimaryColors />
        <ToolbarUser />
      </div>
    </div>
  </v-app-bar>
</template>

<style scoped lang="scss"></style>
